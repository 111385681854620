exports.components = {
  "component---src-pages-about-us-careers-tsx": () => import("./../../../src/pages/about-us/careers.tsx" /* webpackChunkName: "component---src-pages-about-us-careers-tsx" */),
  "component---src-pages-about-us-culture-tsx": () => import("./../../../src/pages/about-us/culture.tsx" /* webpackChunkName: "component---src-pages-about-us-culture-tsx" */),
  "component---src-pages-about-us-our-story-tsx": () => import("./../../../src/pages/about-us/our-story.tsx" /* webpackChunkName: "component---src-pages-about-us-our-story-tsx" */),
  "component---src-pages-about-us-team-tsx": () => import("./../../../src/pages/about-us/team.tsx" /* webpackChunkName: "component---src-pages-about-us-team-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-hire-developers-full-stack-tsx": () => import("./../../../src/pages/hire-developers/full-stack.tsx" /* webpackChunkName: "component---src-pages-hire-developers-full-stack-tsx" */),
  "component---src-pages-hire-developers-mobile-tsx": () => import("./../../../src/pages/hire-developers/mobile.tsx" /* webpackChunkName: "component---src-pages-hire-developers-mobile-tsx" */),
  "component---src-pages-hire-developers-nodejs-tsx": () => import("./../../../src/pages/hire-developers/nodejs.tsx" /* webpackChunkName: "component---src-pages-hire-developers-nodejs-tsx" */),
  "component---src-pages-hire-developers-python-tsx": () => import("./../../../src/pages/hire-developers/python.tsx" /* webpackChunkName: "component---src-pages-hire-developers-python-tsx" */),
  "component---src-pages-hire-developers-react-tsx": () => import("./../../../src/pages/hire-developers/react.tsx" /* webpackChunkName: "component---src-pages-hire-developers-react-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-case-studies-tsx": () => import("./../../../src/pages/our-work/case-studies.tsx" /* webpackChunkName: "component---src-pages-our-work-case-studies-tsx" */),
  "component---src-pages-our-work-industries-tsx": () => import("./../../../src/pages/our-work/industries.tsx" /* webpackChunkName: "component---src-pages-our-work-industries-tsx" */),
  "component---src-pages-our-work-projects-tsx": () => import("./../../../src/pages/our-work/projects.tsx" /* webpackChunkName: "component---src-pages-our-work-projects-tsx" */),
  "component---src-pages-our-work-testimonials-tsx": () => import("./../../../src/pages/our-work/testimonials.tsx" /* webpackChunkName: "component---src-pages-our-work-testimonials-tsx" */),
  "component---src-pages-services-cloud-solutions-tsx": () => import("./../../../src/pages/services/cloud-solutions.tsx" /* webpackChunkName: "component---src-pages-services-cloud-solutions-tsx" */),
  "component---src-pages-services-devops-tsx": () => import("./../../../src/pages/services/devops.tsx" /* webpackChunkName: "component---src-pages-services-devops-tsx" */),
  "component---src-pages-services-mobile-development-tsx": () => import("./../../../src/pages/services/mobile-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-development-tsx" */),
  "component---src-pages-services-ui-ux-design-tsx": () => import("./../../../src/pages/services/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-tsx" */),
  "component---src-pages-services-web-development-tsx": () => import("./../../../src/pages/services/web-development.tsx" /* webpackChunkName: "component---src-pages-services-web-development-tsx" */)
}

